<template>
  <div class="container">
    <img src="../images/success.svg" alt="">
    <p>身份验证成功</p>
    <!-- <van-button size="normal" round plain color="#f49b0d" block @click="close">返回首页</van-button> -->
  </div>
</template>

<script>
export default {
  name: "Success",
  data() {
    return {
      
    };
  },
  created() {},
  methods: {
   close(){
    this.$router.go(-2);
   }
  },
};
</script>

<style scoped lang="scss">
.container {
  // width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  img{
    width: 100px;
    // margin-top: 150px;
  }
  p{
    font-size: 14px;
    line-height: 14px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
</style>
